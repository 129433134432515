import React from 'react'
import { navigate } from 'gatsby';

import AuthUserContext from 'components/firebase/AuthUserContext';
import isBrowser from "utils/isBrowser"
import ROUTES from 'utils/routes'

//routes that only a logged out user can see
export default function LoggedOutRoute({ component: Component, ...rest}) {
  console.log("rest",rest)
  return (
    <AuthUserContext.Consumer>
      {authUser =>
        //redirecting to the "from" is important for users who logged in from a dataset page
        authUser ? (
          isBrowser ? navigate(rest.location.state?.from?.pathname || ROUTES.ACCOUNT) : null
        ) : <Component {...rest} />
      }
    </AuthUserContext.Consumer>
  );
}