import React, { useState } from 'react';
import { Link } from 'gatsby'

import { useFirebase } from 'components/firebase/Firebase';
import ROUTES  from 'utils/routes';
import "./SignIn.scss";

const PasswordForgetPage = () => (
  <div id="passwordforgetpage">
    <h1>Forgot Password</h1>
    <div id="activedivider"></div>
    <PasswordForgetForm />
  </div>
);

export default PasswordForgetPage;



const INITIAL_STATE = {
  email: '',
  success: false,
  error: null,
};

const PasswordForgetForm = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const firebase = useFirebase()

  const invalid = email.trim() === ""

  const onSubmit = e => {
    e.preventDefault();

    if(!invalid) {
      firebase
      .doPasswordReset(email)
      .then(() => {
        setEmail("")
        setError(null)
        setSuccess(true)
      })
      .catch(setError);
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <label>Email</label>

      <input
        className="form-control"
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
      />

      <div className="centering">
        <button disabled={invalid} type="submit" id="teal">Reset Password</button>
      </div>

      {error && <p>{error.message}</p>}

      {success && <p>An email has been sent to your account to reset your password.</p>}
    </form>
  );
}



export const PasswordForgetLink = () => (
  <span>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </span>
);

